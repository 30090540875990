import './Home.css';
import React from "react";
import StructuredData from "react-google-structured-data";

//components
import Videosection from './components/Videosection/Videosection';
import Footer from './components/Footer/Footer';

//images
import wireframe from './images/home/wireframe.jpg';
import graph2d from './images/home/ptaki.jpg';
import model3d from './images/home/mechanics.jpg';

//video
import anim from './video/bw_baner.mp4'

const Home = () => {

        return (
            <div>
                <StructuredData
                    type='Organization'
                    data={{
                    url: 'https://rnd.team',
                    logo: 'https://plotoptix.rnd.team/images/logo_rnd_design_studio_white_1000x500.png',
                    }}
                />
                <div className="beginning"> 
                    <div>
                        <Videosection id = "home" />
                
                        <div className="infoframe">        
                            <div className="infotext">      
                                <p>
                                    <span style={{fontFamily:"Inter", fontSize:"2vw"}}><b>Sharpness is a bourgeois concept</b></span><br/>
                                    <span style={{fontFamily:"Inter", fontSize:"1vw"}}><i>Henri Cartier-Bresson</i></span>
                                </p>
                            </div>        
                        </div>

                        

                            <div className="infoframe">        
                                <p className='title'>Services </p>    
                            </div>
                        </div>
                    </div>

                                        {/*k a f e l k i*/}

                <div className="row-wrapper25home">
                    {/*pierwszy kafelek*/}
                    <div className="kafelekhome">
                        <img src={wireframe} alt="wireframe"></img>
                        <div className='box2home'> 
                        
                            <h2 style={{color: 'white'}}>
                                ML/AI for 2D/3D imaging
                            </h2>

                            <ul style={{color: `white`}}>
                                <li>Analysis</li>
                                <li>Reconstruction</li>
                                <li>Generation</li>
                            </ul>

                        </div>
                    </div>
                    {/*koniec pierwszego kafelka*/}   

                    {/*drugi kafelek*/}
                    <div className="kafelekhome">
                        <img src={model3d} alt="engine"></img>
                        <div className='box2home'>
                            <h2 style={{color: 'white'}}>3D Design</h2>

                            <ul style={{color: `white`}}>
                                <li>Modeling</li>
                                <li>Texturing</li>
                                <li>Rendering</li>
                            </ul>
                        </div>

                    </div>
                    {/*koniec drugiego kafelka*/}

                    {/*trzeci kafelek*/}
                    <div className="kafelekhome">
                        <video src={anim} autoPlay loop muted/>

                        <div className='box2home'>

                            <h2 style={{color: 'white'}}>3D animation</h2>

                            <ul style={{color: `white`}}>
                                <li>Product demo videos</li>
                                <li>Advertising videos</li>
                            </ul>    
                        
                        </div>

                    </div>
                    {/*koniec trzeciego kafelka*/}

                    {/*czwarty kafelek*/}
                    <div className="kafelekhome">
                        <img src={graph2d} alt="graph2d"></img>

                        <div className='box2home'>

                            <h2 style={{color: 'white'}}>Graphic Design</h2>

                            <ul style={{color: `white`}}>
                                <li>Visual identification</li>
                                <li>Websites</li>
                                <li>Publications</li>
                            </ul>  

                        </div>

                    </div>
                    {/*koniec czwartego kafelka*/}
             </div>

 {/*                        <div className="infoframe">
                            <div className='infotext'>
                                <p className="beginning" style={{fontSize: '2vw', textAlign: 'left'}}>
                                    <ul style={{listStyleType:'circle', lineHeight: '1.4'}}>
                                        <li>Creation of graphics and graphics software with a strong background in AI models preparation and image/video processing.</li>
                                        <br />
                                        <li>Support for projects and startups where graphics require innovative solutions and non-standard tools.
                                        </li>
                                        <br />
                                        <li>Design and implementation of artistic graphics projects for visual identification, website design, illustration, as well as 3D modeling, animation and preparation for 2D and 3D printing.
                                        </li>
                                        <br />
                                    </ul>
                                </p>
                            </div>
                        </div> */}
            
             <Footer />

            </div>
        )
}

export default Home;