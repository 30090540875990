
const Portfolio = [
    {
      id: 1,
      path: "/project/avatar3D",
      image: "https://rnd.team/static/media/avatar3d.463a37254e6e9a5af8ac.jpg",
      name: "Cognitiva",
      www: "https://www.cognitiva.dev/",
      category: "designtech", 
      description: "Speaking 3D avatar for"
    },
    {
      id: 2,
      path: "/project/Goypa",
      image: "https://rnd.team/static/media/logogoypa.05fe0063c654f20479bd.jpg",
      name: "GOYPA",
      www: "http://www.goypa.pl/",
      category: "design",
      description: "Logo design for"
    },
    {
      id: 3,
      path: "/project/elproma",
      image: "https://rnd.team/static/media/elproma_cover.60732120bf44d31f6817.jpg",
      name: "Elproma Electronics",
      www: "http://www.elproma.com.pl/",
      category: "design",
      description: "Posters for"
    },
    {
      id: 4,
      path: "/project/ETICS",
      image: "https://rnd.team/static/media/konf_kafelka.f131fc60c7ed1774b19b.jpg",
      name: "SSO",
      www: "https://konferencjaetics.com.pl/",
      category: "design",
      description: "ETICS Conference Identity project for"
    },
    {
      id: 5,
      path: "/project/radio",
      image: "https://rnd.team/static/media/radio_cover.5a6fad5b91b61f96d851.jpg",
      name: "University of Alcala",
      www: "https://www.uah.es/en/",
      category: "tech",
      description: "https://www.uah.es/en/"
    },
    {
      id: 6,
      path: "/project/ETICSwww",
      image: "https://rnd.team/static/media/kafel_wwwetics.2f093240062509d76e4a.jpg",
      name: "ETICS conferences",
      www: "https://konferencjaetics.com.pl/",
      category: "design",
      description: "Web design for"
    },
    {
      id: 7,
      path: "/project/pose",
      image: "https://rnd.team/static/media/pose_cover.c50a4ebc3398b2aea7de.jpg",
      name: "CIŚ",
      www: "https://www.cis.gov.pl/",
      category: "tech",
      description: "Human pose reconstruction for"
    },
    {
      id: 8,
      path: "/project/Illustration",
      image: "https://rnd.team/static/media/illu.29c381ef394d6644821d.jpg",
      name: "4dogs",
      www: "https://www.instagram.com/p/CXilSAvNZFS/",
      category: "design",
      description: "Illustration for catering company"
    },
    {
      id: 9,
      path: "/project/yachts",
      image: "https://rnd.team/static/media/yis_cover.88fca202ad3fcdf34fc1.jpg",
      name: "FSS",
      www: "https://fss.cc/",
      category: "designtech",
      description: "Superyacht visualization for"
    },
    {
      id: 10,
      path: "/plotoptix",
      image: "https://rnd.team/static/media/PlotOptix.260e93964daa945ee16b.jpg",
      name: "PlotOptiX",
      www: "https://github.com/rnd-team-dev/plotoptix",
      category: "tech",
      description: ""
    },
    {
      id: 11,
      path: "/project/noise",
      image: "https://rnd.team/static/media/sculpture_cover.06b734b417b517bc737f.jpg",
      name: "",
      www: "",
      category: "personal",
      description: "Noise sculptures"
    },
    {
      id: 12,
      path: "/project/moon",
      image: "https://rnd.team/static/media/moon.5f5faf295e3cf4712d45.jpg",
      name: "",
      www: "",
      category: "personal",
      description: "Making of the Moon"
    },
    {
      id: 13,
      path: "/project/datavisualization",
      image: "https://rnd.team/static/media/instaproject.5e598f5e5faf64ed38f6.jpg",
      name: "",
      www: "",
      category: "personal",
      description: "Scraping Instagram"
    },
    {
      id: 14,
      path: "/project/attractors",
      image: "https://rnd.team/static/media/attractor.8a898956806d86528b23.jpg",
      name: "",
      www: "",
      category: "personal",
      description: "Attractors"
    },
    {
      id: 15,
      path: "/project/wormances",
      image: "https://rnd.team/static/media/wormance_cover.2b152fbc31a3b7e3a6e5.jpg",
      name: "",
      www: "",
      category: "personal",
      description: "Wormances - generative creatures"
    },
    {
      id: 16,
      path: "/project/generativecontent",
      image: "https://rnd.team/static/media/simplex.fdaf6165fe043c14b934.jpg",
      name: "",
      www: "",
      category: "personal",
      description: "Generative Content"
    }
  ];
  
  export default Portfolio;