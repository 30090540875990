import React from 'react'
import * as THREE from 'three';

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';

import './WebGLBanner.css';

import banner from '../../images/plotoptix/banner.glb';

class WebGLBanner extends React.Component {
    constructor(props) 
    {
         super(props);
         this.windowResize = this.windowResize.bind(this);
         this.mouseMove = this.mouseMove.bind(this);
    }

    sceneInit() 
    {
        console.log('sceneInit');
        const width = window.innerWidth;
        const height = window.innerHeight;
        
        this.renderer = new THREE.WebGLRenderer();
        this.renderer.setSize( width, height );
        this.renderer.setPixelRatio( window.devicePixelRatio );
        this.renderer.setClearColor(0);
        
        this.mount.appendChild( this.renderer.domElement );

        var scene = new THREE.Scene();

        var cam_ar = width / height;
        this.camera = new THREE.PerspectiveCamera( this.getCameraFov(width), cam_ar, 0.1, 1000 );
        this.camera.position.set(0, 100, 0);
        this.camera.lookAt(0, 0, 0);
        this.camera.updateProjectionMatrix();
        scene.add(this.camera);

        this.light = new THREE.PointLight( 0xCCCCCC, 500 );
        this.light.position.set( 0, 10, -3 );
        scene.add( this.light );

        this.light_l = new THREE.PointLight( 0x999999, 500 );
        this.light_l.position.set( -11, 10, -3 );
        scene.add( this.light_l );

        this.light_r = new THREE.PointLight( 0x999999, 500 );
        this.light_r.position.set( 11, 10, -3 );
        scene.add( this.light_r );

        this.composer = new EffectComposer(this.renderer);
        this.composer.addPass(new RenderPass(scene, this.camera));

        var blackPlasticMaterial = new THREE.MeshPhysicalMaterial({
            color: 0x939291,
            roughness: 0.4,
            metalness: 0.7,
            reflectivity: 0.9,
            side: THREE.FrontSide
        });

        this.icospheres_p0 = [];
        this.icospheres_d = [];
        this.icospheres_s = [];
        this.icospheres_r = [];
        this.icospheres = [];

        this.logo_dx = [];
        this.logo_rx = [];
        this.logo = [];

        this.zRotO = 0;
        this.speedup = [];
        this.O = undefined

        var scope = this;      

        const loader = new GLTFLoader();
        loader.load(
            banner, 
            function (gltf) 
            {
                scene.add(gltf.scene);

                gltf.scene.scale.set(1, 1, 1);
                gltf.scene.position.set(0, 0, 0);
                gltf.scene.rotation.set(0, 0, 0);

                console.log(gltf.scene.children.length);

                for (var j = 0; j < gltf.scene.children.length; j++) 
                {
                    var child = gltf.scene.children[j];
                    if (child.type !== "Mesh")
                    {
                        continue;
                    }

                    console.log(child.name);

                    if (child.name.startsWith('Curve')) 
                    {
                        child.rotation.x = 0.1 * (Math.random() - 0.5) * Math.PI;

                        child.material = blackPlasticMaterial;

                        scope.logo.push(child);
                        scope.logo_dx.push(0.0);
                        scope.logo_rx.push(0.0);

                        if (child.name === 'Curve004') 
                        {
                            scope.O = child;
                        }
                    }
                }
            },  
            undefined,
            function ( error ) 
            {
                console.log('An error happened');
                console.error(error, 5);
            }
        );

        this.toScreenPosition = function ( obj, w, h ) {
            var vector = new THREE.Vector3();
    
            var widthHalf = 0.5 * w; 
            var heightHalf = 0.5 * h; 
    
            obj.updateMatrixWorld();
            vector.setFromMatrixPosition( obj.matrixWorld );
            vector.project( this.camera );
    
            vector.x = ( vector.x * widthHalf ) + widthHalf;
            vector.y = - ( vector.y * heightHalf ) + heightHalf;
    
            return { 
                x: vector.x,
                y: vector.y
            };
        };

        this.updateLogo = function () {
            if (this.speedup.length > 0) {
                var s = this.speedup.pop();

                for (var i = 0; i < this.logo_rx.length; i++) {
                    this.logo_rx[i] += this.logo_dx[i] * s;
                }

                this.zRotO += s;
            }

            if (this.O !== undefined) {
                this.zRotO -= 0.01 * this.O.rotation.z;
                this.O.rotation.z += 0.01 * this.zRotO;
                this.zRotO *= 0.99;    
            }

            for (var k = 0; k < this.logo.length; k++) { 
                this.logo_rx[k] -= 0.05 * this.logo[k].rotation.x;
                this.logo[k].rotation.x += 0.01 * this.logo_rx[k];
                this.logo_rx[k] *= 0.999;
            }
        }

        this.updateModel = function () {

            if (this.logo.length > 0) {
                this.updateLogo();
            }
        }
    }

    mousOverO = undefined;
    mouseMove(e) 
    {
        var rect = e.target.getBoundingClientRect();
        var mx = e.clientX - rect.left;
        var my = e.clientY - rect.top;

        if (this.O !== undefined) 
        {
            var posO = this.toScreenPosition(this.O, rect.width, rect.height);
            var dy = my - posO.y;
            if ((this.mousOverO !== undefined) && (this.mousOverO !== (dy < 0))) 
            {
                for (var u = 20; u > 0; u--) 
                {
                    this.speedup.push(0.05 * u);
                }

                for (var l = 0; l < this.logo.length; l++) 
                { 
                    var pos = this.toScreenPosition(this.logo[l], rect.width, rect.height);
                    var dx = 0.03 * (mx - pos.x);
                    dx = dx * dx;
                    this.logo_dx[l] = 0.2 / (1.0 + dx);
                }
            }
            this.mousOverO = dy < 0;
        }
    }

    getCameraFov(width) {
        if (width < 860) {
            return 32;
        }
        else if (width < 1024) {
            return 25;
        }
        else {
            return 21;
        }
    }

    windowResize() 
    {
        const width = window.innerWidth;
        const height = window.innerHeight;
        const cam_ar = width / height;

        this.camera.aspect = cam_ar;
        this.camera.fov = this.getCameraFov(width);
        this.camera.updateProjectionMatrix();

        this.renderer.setSize(width, height);
        this.renderer.setPixelRatio(window.devicePixelRatio);  
    }

    animate() 
    {
        requestAnimationFrame( this.animate.bind(this) );

        this.updateModel();
        
        this.composer.render();
    };

    componentDidMount() {
        this.sceneInit();

        window.addEventListener("resize", this.windowResize, false);

        this.animate();
    }

    render() 
    {
       return ( <div ref={ref => (this.mount = ref)} className='webgl' id={this.props.id} onMouseMove={this.mouseMove} /> )
    }
}

export default WebGLBanner;