import React, {useState} from "react";
import { Link, useNavigate } from 'react-router-dom';

import Portfolio from "./portfolio";

import ScrollToTopOnMount from './components/ScrollToTopOnMount';
import ScrollToTop from "react-scroll-to-top";

//images
import av3d from './images/avatar3d/avatar3d.jpg';
import goypa from './images/goypa/logogoypa.jpg';
import elproma from './images/elproma/elproma_cover.jpg';
import instaproj from './images/instaproject.jpg';
import atraktor from './images/attractors/attractor.jpg';
import plotoptix from './images/PlotOptix.jpg';
import posters from './images/posters/posters.jpg';
import moon from './images/moon/moon.jpg';
import noise from './images/noise/sculpture_cover.jpg';
import yacht from './images/yachts/yis_cover.jpg';
import simplex from './images/simplex.jpg';
import wormance from './images/wormances/wormance_cover.jpg';
import radio from './images/radio/radio_cover.jpg';
import pose from './images/pose/pose_cover.jpg';
import etics from './images/etics24/konf_kafelka.jpg';
import eticswww from './images/etics24/kafel_wwwetics.jpg'
import illu from './images/illu/illu.jpg';

import Footer from './components/Footer/Footer';


const Work = () => {

        const navigate = useNavigate();
  
        const [items, setItems] = useState(Portfolio);
        const [active, setActive] = useState(false);
        const filterItem = (categItem) => {
        const updateItems = Portfolio.filter((curElem) => {            
        
         if (categItem === "personal") {
            return (curElem.category === categItem);
          } else {
            return (curElem.category === categItem || curElem.category === "designtech");
          } 

          //console.log()
             
          });

          setItems(updateItems);
          setActive(true);
        };

        return (
         
         <div> 

            <ScrollToTopOnMount />
            <ScrollToTop smooth />

            <div className="row">
{/*             <div className="infoframe">
              <div className="infotext">  */}
                    {/* <p className="beginning" style={{fontSize: '3vw', textAlign: 'left'}}> */}
                    <p className="heading">
                      We are interested in everything connected to 
                      visual content creation, 
                      processing, 
                      recognition, 
                      and analysis.
                    </p>

                  <div className="beginning">
                  <div className="infoframe">        
                      <p className='title'>Sample projects</p>    
                  </div>
                  </div>
                  
                  <div className="rowbtn">
                    <div className="column4">
                      <p className="pbuttonsel">
                        <a href="javascrip:0;" onClick={() => (filterItem("design"))}>
                          <span style={{color: "white"}}> 
                            DESIGN
                          </span>
                        </a>
                      </p>
                    </div>
                    <div className="column4">
                      <p className="pbuttonsel">
                        <a href="javascrip:0;" onClick={() => (filterItem("tech"))}>
                          <span style={{color: "white"}}> 
                            TECH
                          </span>
                        </a>
                      </p>
                    </div>
                    <div className="column4">
                      <p className="pbuttonsel">
                        <a href="javascrip:0;" onClick={() => (filterItem("personal"))}>
                          <span style={{color: "white"}}> 
                            PERSONAL
                          </span>
                        </a>
                      </p>
                    </div>
                    <div className="column4">
                      <p className="pbuttonsel">
                        <a href="javascrip:0;" onClick={() => setItems(Portfolio)}>
                          <span style={{color: "white"}}> 
                            ALL
                          </span>
                        </a>
                      </p>
                    </div>
                  </div>
                  </div>
{/*                 </div>
              </div>  */}


            <div className="row">
              {items.map((elem) => {
              const { id, path, image, www, name, description} = elem;
                return (
                  <div className="column" id={id}>
                    <div className="content">
                      <Link to={path} onClick={()=>navigate({path})}>
                          <img src={image} alt ={name} style={{width: '100%'}}></img>    
                      </Link> 
                      <p style={{color: 'white'}}>{description} <a href={www} target='_blank'> {name}</a></p>
                    </div>
                  </div>
                );
              })}

            </div>

          <Footer />
           
         </div>
        );
  }


export default Work;