import React from 'react';

import img1 from "../images/4dogs/4dogswww.jpg";
import img2 from "../images/4dogs/elements.png";

import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import ScrollToTop from 'react-scroll-to-top';

import { useNavigate } from 'react-router-dom';

import Footer from '../components/Footer/Footer';

const Fourdogs = () => {

    const navigate = useNavigate();

        return (
            <div>
                <ScrollToTopOnMount />
                <ScrollToTop />

                <div className="beginning"> 
                    <p className="title">Website for the catering company, 4dogs</p>  
                </div>

                <div className="infoframe">
                    <div className="infotext" style={{lineHeight: '1.4', textAlign: 'left'}}>
                        <p>
                            <img src={img1} alt="web design for 4dogs"></img>
                        </p>
                        <p>
                            <img src={img2} alt="web design elements for 4dogs"></img>
                        </p>
                        <p>
                            4dogs online shop redesigned.
                        </p>
                        <p>
                            Client: <a href='https://www.instagram.com/catering4dogs/' target='_blank'>4dogs</a>
                        </p>
                    </div>
                </div>

                <Footer />
            </div>
        )

    }

export default Fourdogs